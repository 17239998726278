export const MoCCurrent10_2 = [
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 20.07,
    rank: 1,
    avg_round: 1.46,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 11.73,
    rank: 2,
    avg_round: 4.9,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 11.56,
    rank: 3,
    avg_round: 4.76,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 8.58,
    rank: 4,
    avg_round: 1.79,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 2.46,
    rank: 5,
    avg_round: 1.84,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 2.08,
    rank: 6,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 1.9,
    rank: 7,
    avg_round: 2.35,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 1.54,
    rank: 8,
    avg_round: 2.58,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 1.11,
    rank: 9,
    avg_round: 4.25,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 1.08,
    rank: 10,
    avg_round: 2.39,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 1.02,
    rank: 11,
    avg_round: 2.58,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.94,
    rank: 12,
    avg_round: 2.68,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.94,
    rank: 12,
    avg_round: 4.25,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.94,
    rank: 12,
    avg_round: 4.03,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.91,
    rank: 15,
    avg_round: 2.7,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.83,
    rank: 16,
    avg_round: 2.25,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.79,
    rank: 17,
    avg_round: 3.19,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.74,
    rank: 18,
    avg_round: 2.32,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.55,
    rank: 19,
    avg_round: 3.65,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.48,
    rank: 20,
    avg_round: 4.3,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.45,
    rank: 21,
    avg_round: 2.64,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.44,
    rank: 22,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.43,
    rank: 23,
    avg_round: 2.57,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.4,
    rank: 24,
    avg_round: 4.19,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.37,
    rank: 25,
    avg_round: 3.76,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.36,
    rank: 26,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.35,
    rank: 27,
    avg_round: 5.64,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Robin',
    app_rate: 0.35,
    rank: 27,
    avg_round: 0.11,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.33,
    rank: 29,
    avg_round: 3.17,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.32,
    rank: 30,
    avg_round: 3.26,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.31,
    rank: 31,
    avg_round: 3.0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.31,
    rank: 31,
    avg_round: 2.47,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.3,
    rank: 33,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.29,
    rank: 34,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.29,
    rank: 34,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 0.27,
    rank: 36,
    avg_round: 6.29,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.27,
    rank: 36,
    avg_round: 2.29,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.27,
    rank: 36,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.26,
    rank: 39,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.26,
    rank: 39,
    avg_round: 3.28,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.25,
    rank: 41,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.25,
    rank: 41,
    avg_round: 3.14,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.24,
    rank: 43,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.24,
    rank: 43,
    avg_round: 3.25,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.24,
    rank: 43,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.24,
    rank: 43,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.23,
    rank: 47,
    avg_round: 3.89,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Moze',
    char_four: 'Aventurine',
    app_rate: 0.23,
    rank: 47,
    avg_round: 4.78,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.22,
    rank: 49,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.22,
    rank: 49,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.22,
    rank: 49,
    avg_round: 5.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.2,
    rank: 52,
    avg_round: 5.69,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.19,
    rank: 53,
    avg_round: 4.08,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.18,
    rank: 54,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.17,
    rank: 55,
    avg_round: 3.43,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.17,
    rank: 55,
    avg_round: 4.64,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.17,
    rank: 55,
    avg_round: 2.78,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.17,
    rank: 55,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.17,
    rank: 55,
    avg_round: 4.0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 0.17,
    rank: 55,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.17,
    rank: 55,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.16,
    rank: 62,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.16,
    rank: 62,
    avg_round: 3.8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.16,
    rank: 62,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.16,
    rank: 62,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.16,
    rank: 62,
    avg_round: 6.2,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.16,
    rank: 62,
    avg_round: 1.8,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 68,
    avg_round: 3.25,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.15,
    rank: 68,
    avg_round: 1.58,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.15,
    rank: 68,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.15,
    rank: 68,
    avg_round: 4.0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 72,
    avg_round: 5.12,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 72,
    avg_round: 4.78,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 74,
    avg_round: 2.0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.13,
    rank: 74,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 74,
    avg_round: 4.8,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 74,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.12,
    rank: 78,
    avg_round: 3.6,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 78,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 78,
    avg_round: 3.0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.12,
    rank: 78,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 82,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 82,
    avg_round: 2.6,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 82,
    avg_round: 7.0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.11,
    rank: 82,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.11,
    rank: 82,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 82,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 88,
    avg_round: 2.88,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 88,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 88,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 88,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 88,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 88,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.1,
    rank: 88,
    avg_round: 4.0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 88,
    avg_round: 6.6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 88,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.09,
    rank: 97,
    avg_round: 1.8,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 97,
    avg_round: 4.33,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 97,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 97,
    avg_round: 4.67,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 97,
    avg_round: 3.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 97,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 97,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.09,
    rank: 97,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.09,
    rank: 97,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 97,
    avg_round: 5.17,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 97,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 108,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 108,
    avg_round: 7.0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 108,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 108,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 108,
    avg_round: 7.0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 108,
    avg_round: 3.43,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 108,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 117,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 117,
    avg_round: 5.8,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.07,
    rank: 117,
    avg_round: 2.4,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 117,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.07,
    rank: 117,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 117,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 117,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.07,
    rank: 117,
    avg_round: 1.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 117,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 117,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 127,
    avg_round: 5.4,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 127,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 127,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 127,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.06,
    rank: 127,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.06,
    rank: 127,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 127,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 127,
    avg_round: 2.0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 127,
    avg_round: 3.33,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 127,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.06,
    rank: 127,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 127,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 127,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 127,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 127,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 127,
    avg_round: 3.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.05,
    rank: 143,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 143,
    avg_round: 5.75,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 143,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 143,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 143,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 143,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 143,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'dr-ratio',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 143,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 143,
    avg_round: 4.67,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 143,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.05,
    rank: 143,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 143,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 143,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.05,
    rank: 143,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 143,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 143,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 143,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 143,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 143,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 143,
    avg_round: 3.4,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 143,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Moze',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 143,
    avg_round: 3.67,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 143,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 143,
    avg_round: 4.33,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 143,
    avg_round: 3.4,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 168,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 168,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 168,
    avg_round: 3.67,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 168,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 168,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 168,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 168,
    avg_round: 4.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 168,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 168,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 168,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 168,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 168,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 168,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 168,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 168,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 168,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Sampo',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 168,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'topaz',
    char_four: 'Robin',
    app_rate: 0.04,
    rank: 168,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 168,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'topaz',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 168,
    avg_round: 3.25,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 168,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 168,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'Gallagher',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 168,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 168,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 168,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 168,
    avg_round: 1.67,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 168,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Bronya',
    char_four: 'Robin',
    app_rate: 0.04,
    rank: 168,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 168,
    avg_round: 4.67,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 168,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 168,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 168,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 200,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 200,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 200,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 200,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 200,
    avg_round: 3.33,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 200,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Bronya',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 200,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 200,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'topaz',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 200,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 200,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 200,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 200,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 200,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 200,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Sampo',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 200,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 200,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 200,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 200,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 200,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 200,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 200,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 4.67,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'march-7th-swordmaster',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 200,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 200,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 200,
    avg_round: 3.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 200,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 200,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 200,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 200,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 200,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 200,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 200,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 200,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 200,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 200,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 200,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 4.33,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 200,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 200,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 200,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 200,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 200,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 200,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Jiaoqiu',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Huohuo',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 269,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Moze',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 269,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 269,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Robin',
    char_three: 'Aventurine',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'march-7th-swordmaster',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Guinaifen',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Himeko',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 269,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Natasha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'topaz',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 269,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Moze',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 269,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 269,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Jiaoqiu',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 269,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Asta',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 269,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Himeko',
    char_three: 'march-7th-swordmaster',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Clara',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 269,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Feixiao',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'march-7th-swordmaster',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Himeko',
    char_three: 'topaz',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 269,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Sampo',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 269,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 269,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 269,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 269,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 269,
    avg_round: 3,
    star_num: '4'
  }
];
