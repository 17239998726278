export const MoCCurrent12_2 = [
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 29.31,
    rank: 1,
    avg_round: 8.61,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 23.16,
    rank: 2,
    avg_round: 7.93,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 8.89,
    rank: 3,
    avg_round: 8.05,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 7.02,
    rank: 4,
    avg_round: 7.01,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 2.35,
    rank: 5,
    avg_round: 9.41,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 1.1,
    rank: 6,
    avg_round: 8.89,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 1.03,
    rank: 7,
    avg_round: 7.3,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.93,
    rank: 8,
    avg_round: 6.58,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.9,
    rank: 9,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.86,
    rank: 10,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.81,
    rank: 11,
    avg_round: 8.56,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.78,
    rank: 12,
    avg_round: 9.62,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.6,
    rank: 13,
    avg_round: 9.4,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.54,
    rank: 14,
    avg_round: 6.84,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.49,
    rank: 15,
    avg_round: 9.8,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.45,
    rank: 16,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.4,
    rank: 17,
    avg_round: 8.91,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.36,
    rank: 18,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.35,
    rank: 19,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.33,
    rank: 20,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.33,
    rank: 20,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.31,
    rank: 22,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.31,
    rank: 22,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.31,
    rank: 22,
    avg_round: 8.44,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.29,
    rank: 25,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.29,
    rank: 25,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.29,
    rank: 25,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.28,
    rank: 28,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.26,
    rank: 29,
    avg_round: 9.18,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.24,
    rank: 30,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.24,
    rank: 30,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.24,
    rank: 30,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.24,
    rank: 30,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.23,
    rank: 34,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.19,
    rank: 35,
    avg_round: 9.17,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.19,
    rank: 35,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.18,
    rank: 37,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.18,
    rank: 37,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.18,
    rank: 37,
    avg_round: 6.15,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.18,
    rank: 37,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.17,
    rank: 41,
    avg_round: 9.44,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.16,
    rank: 42,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.16,
    rank: 42,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.15,
    rank: 44,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.15,
    rank: 44,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.15,
    rank: 44,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.14,
    rank: 47,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.13,
    rank: 48,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.13,
    rank: 48,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 48,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.12,
    rank: 51,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.12,
    rank: 51,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.12,
    rank: 51,
    avg_round: 9.75,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.11,
    rank: 54,
    avg_round: 9.11,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.11,
    rank: 54,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.11,
    rank: 54,
    avg_round: 5.8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.11,
    rank: 54,
    avg_round: 6.89,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.11,
    rank: 54,
    avg_round: 2.4,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.11,
    rank: 54,
    avg_round: 9.29,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 60,
    avg_round: 8.11,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 60,
    avg_round: 9.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 60,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 60,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 60,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 60,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.1,
    rank: 60,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.1,
    rank: 60,
    avg_round: 8.4,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 60,
    avg_round: 8.38,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 60,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 60,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 60,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Guinaifen',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 60,
    avg_round: 9.2,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.1,
    rank: 60,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 74,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 74,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 74,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 74,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 74,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Robin',
    app_rate: 0.09,
    rank: 74,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.09,
    rank: 74,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Asta',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 74,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 74,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 74,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 74,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 74,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 74,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 87,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 87,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 87,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 87,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 87,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 87,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 87,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 87,
    avg_round: 6.75,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 87,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 87,
    avg_round: 9.29,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 87,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 98,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 98,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 98,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.07,
    rank: 98,
    avg_round: 9.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 98,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 103,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-harmony',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 103,
    avg_round: 8.4,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 103,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Sparkle',
    app_rate: 0.06,
    rank: 103,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.06,
    rank: 103,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 103,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 103,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 103,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 103,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Robin',
    app_rate: 0.06,
    rank: 103,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.06,
    rank: 103,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 103,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 115,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 115,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 115,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 115,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 115,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 115,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-harmony',
    char_two: 'ruan-mei',
    char_three: 'Gallagher',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 115,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'Sparkle',
    app_rate: 0.05,
    rank: 115,
    avg_round: 5.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 115,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 115,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 115,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 115,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 115,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 115,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 115,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 115,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 115,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 115,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 133,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Sparkle',
    app_rate: 0.04,
    rank: 133,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 133,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 133,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 133,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 133,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 133,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Robin',
    app_rate: 0.04,
    rank: 133,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 133,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 133,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 133,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 133,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 133,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 133,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 133,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.04,
    rank: 133,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 133,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 133,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 151,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 151,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 151,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 151,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 151,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Moze',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 151,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 151,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 151,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 151,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 151,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Welt',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 151,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 151,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 151,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.03,
    rank: 151,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 151,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 151,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 151,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 151,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 151,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 151,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 151,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'Gallagher',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 151,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 151,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 151,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 151,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 151,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 151,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 151,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 151,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Welt',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 151,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 151,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 151,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 151,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Guinaifen',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 151,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 185,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 185,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-harmony',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 185,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 185,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 185,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Jiaoqiu',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 185,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Boothill',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 185,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Jade',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Asta',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 185,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 185,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Hanya',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 185,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 185,
    avg_round: 99.99,
    star_num: '4'
  }
];
