export const HSRMocHisto = [
  {
    slug: 'feixiao',
    score: 6.59,
    usage: 42.11,
    role: 'dps'
  },
  {
    slug: 'yunli',
    score: 7.82,
    usage: 9.72,
    role: 'dps'
  },
  {
    slug: 'acheron',
    score: 8.07,
    usage: 41.33,
    role: 'dps'
  },
  {
    slug: 'dr-ratio',
    score: 8.74,
    usage: 6.04,
    role: 'dps'
  },
  {
    slug: 'clara',
    score: 8.9,
    usage: 3.8,
    role: 'dps'
  },
  {
    slug: 'jing-yuan',
    score: 8.91,
    usage: 1.03,
    role: 'dps'
  },
  {
    slug: 'imbibitor-lunae',
    score: 8.97,
    usage: 5.43,
    role: 'dps'
  },
  {
    slug: 'himeko',
    score: 9.21,
    usage: 5.98,
    role: 'dps'
  },
  {
    slug: 'blade',
    score: 9.22,
    usage: 2.39,
    role: 'dps'
  },
  {
    slug: 'seele',
    score: 10.2,
    usage: 0.61,
    role: 'dps'
  },
  {
    slug: 'yanqing',
    score: 10.24,
    usage: 0.16,
    role: 'dps'
  },
  {
    slug: 'qingque',
    score: 10.24,
    usage: 0.34,
    role: 'dps'
  },
  {
    slug: 'argenti',
    score: 10.35,
    usage: 0.28,
    role: 'dps'
  },
  {
    slug: 'jingliu',
    score: 10.37,
    usage: 2.28,
    role: 'dps'
  },
  {
    slug: 'serval',
    score: 11,
    usage: 0.07,
    role: 'dps'
  },
  {
    slug: 'herta',
    score: 11,
    usage: 0.13,
    role: 'dps'
  },
  {
    slug: 'misha',
    score: 11,
    usage: 0.07,
    role: 'dps'
  },
  {
    slug: 'hook',
    score: 11,
    usage: 0.06,
    role: 'dps'
  },
  {
    slug: 'dan-heng',
    score: 11,
    usage: 0.04,
    role: 'dps'
  },
  {
    slug: 'arlan',
    score: 11,
    usage: 0.01,
    role: 'dps'
  },
  {
    slug: 'trailblazer-destruction',
    score: 11,
    usage: 0.0,
    role: 'dps'
  },
  {
    slug: 'moze',
    score: 6.49,
    usage: 4.53,
    role: 'specialist'
  },
  {
    slug: 'topaz',
    score: 6.72,
    usage: 27.27,
    role: 'specialist'
  },
  {
    slug: 'march-7th-swordmaster',
    score: 7.13,
    usage: 19.81,
    role: 'specialist'
  },
  {
    slug: 'rappa',
    score: 7.62,
    usage: 19.32,
    role: 'specialist',
    new: true
  },
  {
    slug: 'firefly',
    score: 7.66,
    usage: 63.11,
    role: 'specialist'
  },
  {
    slug: 'boothill',
    score: 8.13,
    usage: 5.25,
    role: 'specialist'
  },
  {
    slug: 'black-swan',
    score: 8.74,
    usage: 16.16,
    role: 'specialist'
  },
  {
    slug: 'kafka',
    score: 8.88,
    usage: 13.34,
    role: 'specialist'
  },
  {
    slug: 'jade',
    score: 9.42,
    usage: 1.72,
    role: 'specialist'
  },
  {
    slug: 'welt',
    score: 9.78,
    usage: 0.57,
    role: 'specialist'
  },
  {
    slug: 'sampo',
    score: 9.79,
    usage: 0.61,
    role: 'specialist'
  },
  {
    slug: 'sushang',
    score: 10.12,
    usage: 0.09,
    role: 'specialist'
  },
  {
    slug: 'luka',
    score: 10.36,
    usage: 0.14,
    role: 'specialist'
  },
  {
    slug: 'xueyi',
    score: 11,
    usage: 0.09,
    role: 'specialist'
  },
  {
    slug: 'robin',
    score: 6.84,
    usage: 57.57,
    role: 'amplifier'
  },
  {
    slug: 'jiaoqiu',
    score: 7.56,
    usage: 23.48,
    role: 'amplifier'
  },
  {
    slug: 'trailblazer-harmony',
    score: 7.7,
    usage: 76.68,
    role: 'amplifier'
  },
  {
    slug: 'ruan-mei',
    score: 7.72,
    usage: 86.53,
    role: 'amplifier'
  },
  {
    slug: 'pela',
    score: 8,
    usage: 19.66,
    role: 'amplifier'
  },
  {
    slug: 'tingyun',
    score: 8.09,
    usage: 9.62,
    role: 'amplifier'
  },
  {
    slug: 'bronya',
    score: 8.26,
    usage: 10.35,
    role: 'amplifier'
  },
  {
    slug: 'sparkle',
    score: 8.31,
    usage: 26.24,
    role: 'amplifier'
  },
  {
    slug: 'silver-wolf',
    score: 8.44,
    usage: 11.24,
    role: 'amplifier'
  },
  {
    slug: 'guinaifen',
    score: 9.24,
    usage: 1.34,
    role: 'amplifier'
  },
  {
    slug: 'asta',
    score: 9.44,
    usage: 0.85,
    role: 'amplifier'
  },
  {
    slug: 'hanya',
    score: 10.11,
    usage: 0.33,
    role: 'amplifier'
  },
  {
    slug: 'yukong',
    score: 11,
    usage: 0.12,
    role: 'amplifier'
  },
  {
    slug: 'lingsha',
    score: 6.93,
    usage: 33.83,
    role: 'sustain'
  },
  {
    slug: 'aventurine',
    score: 7.13,
    usage: 47.37,
    role: 'sustain'
  },
  {
    slug: 'fu-xuan',
    score: 7.62,
    usage: 30.52,
    role: 'sustain'
  },
  {
    slug: 'gallagher',
    score: 7.72,
    usage: 58.98,
    role: 'sustain'
  },
  {
    slug: 'huohuo',
    score: 7.96,
    usage: 20.21,
    role: 'sustain'
  },
  {
    slug: 'luocha',
    score: 8.25,
    usage: 10.8,
    role: 'sustain'
  },
  {
    slug: 'gepard',
    score: 8.4,
    usage: 1.68,
    role: 'sustain'
  },
  {
    slug: 'lynx',
    score: 8.43,
    usage: 1.12,
    role: 'sustain'
  },
  {
    slug: 'bailu',
    score: 8.77,
    usage: 1.27,
    role: 'sustain'
  },
  {
    slug: 'natasha',
    score: 10.24,
    usage: 0.1,
    role: 'sustain'
  },
  {
    slug: 'march-7th',
    score: 10.42,
    usage: 0.15,
    role: 'sustain'
  },
  {
    slug: 'trailblazer-preservation',
    score: 11,
    usage: 0.01,
    role: 'sustain'
  }
];
