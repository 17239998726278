export const MoCCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    rarity: 'Limited 5*',
    current_app_rate: 48.75,
    prev_app_rate: 25.79,
    round_eidolons: 6.34,
    current_avg_round: 8.5,
    prev_avg_round: 7.98
  },
  {
    char: 'argenti',
    name: 'Argenti',
    rarity: 'Limited 5*',
    current_app_rate: 0.04,
    prev_app_rate: 0.08,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'arlan',
    name: 'Arlan',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0.01,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'asta',
    name: 'Asta',
    rarity: '4*',
    current_app_rate: 0.51,
    prev_app_rate: 1.41,
    round_eidolons: 9.22,
    current_avg_round: 9.9,
    prev_avg_round: 8.93
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    rarity: 'Limited 5*',
    current_app_rate: 57.72,
    prev_app_rate: 43.34,
    round_eidolons: 6.1,
    current_avg_round: 8.16,
    prev_avg_round: 6.08
  },
  {
    char: 'bailu',
    name: 'Bailu',
    rarity: 'Standard 5*',
    current_app_rate: 0.89,
    prev_app_rate: 1.13,
    round_eidolons: 8,
    current_avg_round: 9.35,
    prev_avg_round: 8.09
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    rarity: 'Limited 5*',
    current_app_rate: 22.41,
    prev_app_rate: 6.41,
    round_eidolons: 7.64,
    current_avg_round: 8.83,
    prev_avg_round: 8.88
  },
  {
    char: 'blade',
    name: 'Blade',
    rarity: 'Limited 5*',
    current_app_rate: 1.28,
    prev_app_rate: 1.43,
    round_eidolons: 8.16,
    current_avg_round: 10,
    prev_avg_round: 9.35
  },
  {
    char: 'boothill',
    name: 'Boothill',
    rarity: 'Limited 5*',
    current_app_rate: 1.14,
    prev_app_rate: 4.95,
    round_eidolons: 7.11,
    current_avg_round: 9.17,
    prev_avg_round: 7.57
  },
  {
    char: 'bronya',
    name: 'Bronya',
    rarity: 'Standard 5*',
    current_app_rate: 6.6,
    prev_app_rate: 7.91,
    round_eidolons: 5.53,
    current_avg_round: 9.08,
    prev_avg_round: 7.6
  },
  {
    char: 'clara',
    name: 'Clara',
    rarity: 'Standard 5*',
    current_app_rate: 0.59,
    prev_app_rate: 4.59,
    round_eidolons: 8.23,
    current_avg_round: 9.56,
    prev_avg_round: 8.49
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    rarity: '4*',
    current_app_rate: 0.04,
    prev_app_rate: 0.03,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    rarity: 'Limited 5*',
    current_app_rate: 2.33,
    prev_app_rate: 2.94,
    round_eidolons: 8.66,
    current_avg_round: 9.52,
    prev_avg_round: 8.42
  },
  {
    char: 'feixiao',
    name: 'Feixiao',
    rarity: 'Limited 5*',
    current_app_rate: 44.32,
    prev_app_rate: 59.65,
    round_eidolons: 5.44,
    current_avg_round: 7.69,
    prev_avg_round: 6.01
  },
  {
    char: 'trailblazer-preservation',
    name: 'Fire Trailblazer',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0.02,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'firefly',
    name: 'Firefly',
    rarity: 'Limited 5*',
    current_app_rate: 60.52,
    prev_app_rate: 75.44,
    round_eidolons: 6.3,
    current_avg_round: 8.43,
    prev_avg_round: 6.56
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    rarity: 'Limited 5*',
    current_app_rate: 23.54,
    prev_app_rate: 27.58,
    round_eidolons: 6.86,
    current_avg_round: 8.32,
    prev_avg_round: 7
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    rarity: '4*',
    current_app_rate: 53.48,
    prev_app_rate: 64.57,
    round_eidolons: 6.96,
    current_avg_round: 8.44,
    prev_avg_round: 6.85
  },
  {
    char: 'gepard',
    name: 'Gepard',
    rarity: 'Standard 5*',
    current_app_rate: 1.15,
    prev_app_rate: 1.38,
    round_eidolons: 7.32,
    current_avg_round: 8.77,
    prev_avg_round: 8.41
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    rarity: '4*',
    current_app_rate: 1.37,
    prev_app_rate: 1.89,
    round_eidolons: 8.16,
    current_avg_round: 9.36,
    prev_avg_round: 9.31
  },
  {
    char: 'hanya',
    name: 'Hanya',
    rarity: '4*',
    current_app_rate: 0.1,
    prev_app_rate: 0.22,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'herta',
    name: 'Herta',
    rarity: '4*',
    current_app_rate: 0.14,
    prev_app_rate: 0.04,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'himeko',
    name: 'Himeko',
    rarity: 'Standard 5*',
    current_app_rate: 5,
    prev_app_rate: 12.75,
    round_eidolons: 8.42,
    current_avg_round: 9.36,
    prev_avg_round: 7.26
  },
  {
    char: 'hook',
    name: 'Hook',
    rarity: '4*',
    current_app_rate: 0.03,
    prev_app_rate: 0.13,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    rarity: 'Limited 5*',
    current_app_rate: 15.22,
    prev_app_rate: 15.88,
    round_eidolons: 6.77,
    current_avg_round: 8.57,
    prev_avg_round: 7.33
  },
  {
    char: 'trailblazer-harmony',
    name: 'Imaginary Trailblazer',
    rarity: '4*',
    current_app_rate: 83.73,
    prev_app_rate: 86.53,
    round_eidolons: 6.29,
    current_avg_round: 8.34,
    prev_avg_round: 6.7
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    rarity: 'Limited 5*',
    current_app_rate: 2.99,
    prev_app_rate: 2.94,
    round_eidolons: 7.31,
    current_avg_round: 9.5,
    prev_avg_round: 9.26
  },
  {
    char: 'jade',
    name: 'Jade',
    rarity: 'Limited 5*',
    current_app_rate: 1.77,
    prev_app_rate: 1.14,
    round_eidolons: 5.68,
    current_avg_round: 9,
    prev_avg_round: 99.99
  },
  {
    char: 'jiaoqiu',
    name: 'Jiaoqiu',
    rarity: 'Limited 5*',
    current_app_rate: 26.4,
    prev_app_rate: 18.63,
    round_eidolons: 5.47,
    current_avg_round: 7.83,
    prev_avg_round: 7.67
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    rarity: 'Limited 5*',
    current_app_rate: 1.64,
    prev_app_rate: 0.56,
    round_eidolons: 8.7,
    current_avg_round: 9.25,
    prev_avg_round: 9.11
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    rarity: 'Limited 5*',
    current_app_rate: 0.35,
    prev_app_rate: 0.37,
    round_eidolons: 7.81,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'kafka',
    name: 'Kafka',
    rarity: 'Limited 5*',
    current_app_rate: 18.52,
    prev_app_rate: 5.44,
    round_eidolons: 7.78,
    current_avg_round: 8.94,
    prev_avg_round: 9.03
  },
  {
    char: 'lingsha',
    name: 'Lingsha',
    rarity: 'Limited 5*',
    current_app_rate: 36.63,
    prev_app_rate: 31.02,
    round_eidolons: 5.43,
    current_avg_round: 7.7,
    prev_avg_round: 6.16
  },
  {
    char: 'luka',
    name: 'Luka',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0.03,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'luocha',
    name: 'Luocha',
    rarity: 'Limited 5*',
    current_app_rate: 6.76,
    prev_app_rate: 9.3,
    round_eidolons: 7.51,
    current_avg_round: 8.91,
    prev_avg_round: 7.58
  },
  {
    char: 'lynx',
    name: 'Lynx',
    rarity: '4*',
    current_app_rate: 0.37,
    prev_app_rate: 0.64,
    round_eidolons: 7.81,
    current_avg_round: 8.7,
    prev_avg_round: 8.21
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    rarity: '4*',
    current_app_rate: 0.08,
    prev_app_rate: 0.09,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - The Hunt',
    rarity: '4*',
    current_app_rate: 17.2,
    prev_app_rate: 25.4,
    round_eidolons: 5.95,
    current_avg_round: 7.92,
    prev_avg_round: 6.63
  },
  {
    char: 'misha',
    name: 'Misha',
    rarity: '4*',
    current_app_rate: 0.01,
    prev_app_rate: 0,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'moze',
    name: 'Moze',
    rarity: '4*',
    current_app_rate: 6.11,
    prev_app_rate: 5,
    round_eidolons: 6.37,
    current_avg_round: 7.72,
    prev_avg_round: 6.28
  },
  {
    char: 'natasha',
    name: 'Natasha',
    rarity: '4*',
    current_app_rate: 0.03,
    prev_app_rate: 0.11,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'pela',
    name: 'Pela',
    rarity: '4*',
    current_app_rate: 19.09,
    prev_app_rate: 9.37,
    round_eidolons: 7.27,
    current_avg_round: 8.32,
    prev_avg_round: 7.91
  },
  {
    char: 'trailblazer-destruction',
    name: 'Physical Trailblazer',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'qingque',
    name: 'Qingque',
    rarity: '4*',
    current_app_rate: 0.31,
    prev_app_rate: 0.18,
    round_eidolons: 7.62,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'rappa',
    name: 'Rappa',
    rarity: 'Limited 5*',
    current_app_rate: 19.32,
    prev_app_rate: 19.32,
    round_eidolons: 5.7,
    current_avg_round: 7.62,
    prev_avg_round: 7.62
  },
  {
    char: 'robin',
    name: 'Robin',
    rarity: 'Limited 5*',
    current_app_rate: 56.13,
    prev_app_rate: 71.17,
    round_eidolons: 5.67,
    current_avg_round: 7.73,
    prev_avg_round: 6.1
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    rarity: 'Limited 5*',
    current_app_rate: 89.32,
    prev_app_rate: 90.81,
    round_eidolons: 6.25,
    current_avg_round: 8.32,
    prev_avg_round: 6.73
  },
  {
    char: 'sampo',
    name: 'Sampo',
    rarity: '4*',
    current_app_rate: 0.73,
    prev_app_rate: 0.2,
    round_eidolons: 8.64,
    current_avg_round: 9.75,
    prev_avg_round: 99.99
  },
  {
    char: 'seele',
    name: 'Seele',
    rarity: 'Limited 5*',
    current_app_rate: 0.34,
    prev_app_rate: 0.42,
    round_eidolons: 7.82,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'serval',
    name: 'Serval',
    rarity: '4*',
    current_app_rate: 0.18,
    prev_app_rate: 0.02,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    rarity: 'Limited 5*',
    current_app_rate: 9.1,
    prev_app_rate: 6.86,
    round_eidolons: 7.75,
    current_avg_round: 8.63,
    prev_avg_round: 8.47
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    rarity: 'Limited 5*',
    current_app_rate: 23.17,
    prev_app_rate: 19.35,
    round_eidolons: 5.8,
    current_avg_round: 8.97,
    prev_avg_round: 7.85
  },
  {
    char: 'sushang',
    name: 'Sushang',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 0.07,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    rarity: '4*',
    current_app_rate: 4.05,
    prev_app_rate: 6.53,
    round_eidolons: 7.74,
    current_avg_round: 9.16,
    prev_avg_round: 7.59
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    rarity: 'Limited 5*',
    current_app_rate: 24.47,
    prev_app_rate: 37.57,
    round_eidolons: 5.52,
    current_avg_round: 7.6,
    prev_avg_round: 5.84
  },
  {
    char: 'welt',
    name: 'Welt',
    rarity: 'Standard 5*',
    current_app_rate: 0.57,
    prev_app_rate: 0.5,
    round_eidolons: 6.46,
    current_avg_round: 99.99,
    prev_avg_round: 9.22
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    rarity: '4*',
    current_app_rate: 0.04,
    prev_app_rate: 0.1,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    rarity: 'Standard 5*',
    current_app_rate: 0.01,
    prev_app_rate: 0.01,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'yukong',
    name: 'Yukong',
    rarity: '4*',
    current_app_rate: 0.04,
    prev_app_rate: 0.05,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'yunli',
    name: 'Yunli',
    rarity: 'Limited 5*',
    current_app_rate: 3.2,
    prev_app_rate: 9.77,
    round_eidolons: 5.89,
    current_avg_round: 9,
    prev_avg_round: 7.17
  }
];
